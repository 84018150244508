<template>
    <div class="Payment">
        <div class="paymain">
            <div style="padding: 10px 0;">
                <!-- 扫码付款 -->
                <div class="payBox">{{$t('mx_mobile.Client.1585283401134')}}</div>
                <img width="200" height="200" src="/images/pay/zhifubao.png" />
                <!-- 支付宝扫一扫，立即付款 -->
                <div>{{$t('mx_mobile.Client.1585283396231')}}</div>
            </div>
            <div style="padding: 10px 0;">
                <!-- 线下汇款 -->
                <div class="bankTitle">{{$t('mx_mobile.Client.1585283439142')}}</div>
                <div class="bankBox">
                    <!-- 开户名称 -->
                    <span class="bankName">{{$t('mx_mobile.Client.1585284627987')}}</span>
                    <div class="bankright"><span class="bankText">上海孚盟软件有限公司</span></div>
                </div>
                <div class="bankBox">
                    <!-- 开户银行 -->
                    <span class="bankName">{{$t('mx_mobile.Client.1585284645889')}}</span>
                    <div class="bankright">
                        <!-- 复制 -->
                        <button @click="Copy1" data-clipboard-target="#foo1" ref="copy1">{{$t('mx_mobile.Client.1585285266106')}}</button>
                        <span class="bankText" id="foo1">平安银行上海西南支行</span>
                    </div>
                </div>
                <div class="bankBox">
                    <!-- 银行账户 -->
                    <span class="bankName">{{$t('mx_mobile.Client.1585284646072')}}</span>
                    <div class="bankright">
                        <!-- 复制 -->
                        <button @click="Copy" data-clipboard-target="#foo" ref="copy">{{$t('mx_mobile.Client.1585285266106')}}</button>
                        <span class="bankText" id="foo">11006935635401</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import titleMixin from '../../../mixin/title'
import Clipboard from 'clipboard'
export default {
    name: 'Setting',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Client.1585282870084') }, // '付款方式'
    data() {
        return {
            value: ''
        }
    },
    computed: {
    },
    created() {
    },
    methods: {
        Copy1() {
            let clipboard = new Clipboard(this.$refs.copy1) // 实例化对象
            let that = this
            clipboard.on('success', (e) => {
                that.$toast(this.$t('mx_mobile.Doc.1585194769281')) // '复制成功'
                e.clearSelection() // 清空粘贴区1
            })
        },
        Copy() {
            let clipboard = new Clipboard(this.$refs.copy) // 实例化对象
            let that = this
            clipboard.on('success', (e) => {
                that.$toast(this.$t('mx_mobile.Doc.1585194769281')) // '复制成功'
                e.clearSelection() // 清空粘贴区1
            })
        }
    },
    watch: {

    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Payment {
    .paymain {
        width: 72%;
        margin: 0 auto;
    }
    background: #fff;
    height: 100%;
    position: absolute;
    width: 100%;
    .bankTitle {
        font-size: 16px;
        margin: 20px 0;
    }
    .bankBox {
        overflow: hidden;
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        .bankName {
            float: left;
            width: 21%;
            margin-right: 4%;
        }
        .bankright {
            float: right;
            overflow: hidden;
            width: 75%;
        }
        #foo,
        .bankText {
            float: left;
        }
        button {
            float: right;
            margin-left: 5px;
            background: #fff;
            padding: 0 4px;
            border: 1px solid #d0021b;
            color: #d0021b;
            border-radius: 3px;
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            margin-top: 10px;
        }
    }
    .payBox {
        height: 40px;
        line-height: 40px;
        font-size: 16px;
    }
}
</style>
